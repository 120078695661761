// Colours
$white: #ffffff;
$black: #000000;
$light-grey: #dfdfdf;
$dark-grey: #212121;

$teal: #2E6D6E;

$primary: #01243a;
$secondary: #1c4c56;

$positive: #0ab04b;
$negative: #e64747;

$label: #1c4c56;
$input-bg: #ffffff; 
$input-border: #dfdfdf;
$input-font: #ffffff; 

// Spacing
$btn-padding: 0.75rem 1rem;

// Typography

$heading-font: Tahoma,Verdana,Arial,Helvetica;
$font: "roboto", sans-serif;