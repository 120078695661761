@tailwind utilities;

@import "~normalize.css/normalize.css";
@import "partials/_variables.scss";
@import "partials/_accordion.scss";
@import "partials/_slider.scss";
@import "partials/_columns.scss";
@import "partials/_buttons.scss";
@import "partials/_forms.scss";
@import "partials/_messages.scss";
@import "partials/_breakpoints.scss";
@import "partials/_cart.scss";
@import "tiles";

// Font import
@import url("https://use.typekit.net/olm8ucc.css");

$max-width: 1440px;
$min-width: 320px;

// Global Styles
*,
*:before,
*:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body,
html {
    font-family: $font;
    position: relative;
    text-align: center;
    -webkit-overflow-scrolling: touch;
    background: $black;
    color: $white;

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font;
    font-weight: 600;
}

p,
a {
    color: $white;
    text-align: justify;
    letter-spacing: 0.8px;
}

a {
    &:hover {
        color: $teal;
    }
}

// Error pages
.text-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0.5em;
}

.container {
    padding: 0 40px;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;

    @include md {
        padding: 0 20px;
    }
}

.row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 auto;

    @include md {
        flex-direction: column;
    }
}

nav {
    &.breadcrumbs {
        text-align: left;

        ul {
            display: inline-block;

            li {
                display: inline-block;

                &:not(:last-child)::after {
                    content: ">";
                    display: inline-block;
                }
            }
        }
    }
}

body>header {
    nav {
        ul {
            li {
                list-style: none;
                display: inline-block;
            }
        }

        // &.user {
        //     .cart {
        //         box-shadow: 5px 5px 5px rgba($off-black, 0.2);
        //         .cart-items {
        //             padding-top: 10px!important;
        //             padding-bottom: 0!important;
        //             .cart-item {
        //                 padding: 0 0;
        //                 .item-details {
        //                     flex: 1 1 auto;
        //                 }
        //                 .item-qty {
        //                     flex: 0 0 auto;
        //                 }
        //                 .item-price {
        //                     flex: 1 1 auto;
        //                     text-align: right;
        //                 }
        //                 .item-remove {
        //                     flex: 0 0 auto;
        //                     margin-right: -10px;
        //                     margin-left: 10px;
        //                 }
        //             }
        //         }
        //         .totals {
        //             font-weight: 600;
        //             padding:  5px 10px 5px 5px;
        //             border-top: 1px dotted $off-black;
        //         }
        //     }
        // }
    }
}

main {

    &.account,
    &.checkout {
        ul.sections {
            justify-content: center;

            li {
                display: block;
                padding: 0 10px;

                a {
                    display: block;
                    border: 1px solid $black;
                    border-radius: 5px;
                    padding: 10px 20px;
                }

                &.current {
                    a {
                        background: $black;
                        color: $white;
                    }
                }
            }
        }

        form {
            max-width: 600px;
            flex: 1 0 auto;
        }

        &.address,
        &.cart {
            form {
                max-width: 980px;
                flex: 1 0 auto;
            }
        }
    }

    &.checkout {
        &.cart {
            .cart-items {
                &>li.cart-item {
                    padding: 5px 10px;

                    input {
                        margin: 0;
                    }

                    .item-image {
                        width: 40px;
                        height: 40px;
                        flex: 0 0 auto;
                    }

                    .item-details {
                        text-align: left;
                        justify-self: flex-start !important;
                        flex: 1 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                    }

                    .item-qty {
                        flex: 0 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);

                        input {
                            display: inline-block;
                            width: 8rem;
                        }
                    }

                    .item-price {
                        flex: 0 0 auto;
                        text-align: right;
                        max-width: 8rem;
                        padding: 0 10px;
                    }

                    .item-remove {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .totals {
                font-weight: 600;
                padding: 0 20px;

                span {
                    display: inline-block;
                    min-width: 8rem;
                }
            }
        }

        &.shipping,
        &.payment {
            &>.container>.row {
                justify-content: center;
                align-items: flex-start;
            }

            form {
                margin: 0;

                fieldset {
                    &.shipping-methods {
                        padding: 20px;

                        label {
                            padding: 10px;
                            display: flex;
                            align-items: center;

                            input {
                                margin-right: 1em;
                            }

                            span {
                                font-weight: 600;
                                display: inline-block;
                                margin-left: 1em;
                            }
                        }
                    }

                    &.buttons {
                        display: flex;
                        justify-content: center;

                        button,
                        .button {
                            margin: 2em 10px 0;
                        }
                    }
                }

                .payment-method {
                    margin-top: 20px;
                    padding-top: 20px;
                }

                .message {
                    padding: 20px 10px;
                    text-align: left;

                    ul {
                        margin: 1em 2em;
                    }
                }
            }

            .totals {
                border-radius: 5px;
                padding: 40px 40px;
                max-width: 330px;
                margin: 0 10px;
                flex: 1 0 auto;
                text-align: left;

                ul {
                    margin: 1em 0;

                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        padding: 0.5em 5px;

                        &.total {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

main {
    &.products {
        ul.row {
            justify-content: flex-start;

            li.product {
                list-style: none;
                border: 1px solid $black;
                overflow: hidden;
                width: calc(25% - 20px);
                margin: 10px;

                a {
                    &.image {
                        display: block;
                        width: 100%;
                        padding-top: 100%;
                        background-size: contain;
                    }
                }

                div {
                    text-align: left;
                    padding: 20px 40px;
                }
            }
        }
    }
}

// Flatpickr
.flatpickr-wrapper {
    @apply w-full;
}

.flatpickr-calendar.open {
    z-index: 1000 !important;
    filter: none !important;
}

.flatpickr-months {
    @apply mb-2 text-gray-800;
}

.flatpickr-next-month,
.flatpickr-prev-month {
    @apply text-gray-800;

    svg {
        fill: currentColor !important;
    }
}

.flatpickr-current-month span.cur-month:hover,
.numInputWrapper:hover {
    @apply bg-transparent;
}

.flatpickr-day {
    @apply border-0;

    &.today {
        @apply font-semibold;

        &:hover {
            color: inherit;
            @apply bg-gray-400;
        }
    }

    &:hover {
        @apply bg-gray-400;
    }

    &.selected {
        @apply bg-blue-600 text-white;

        &:hover,
        &.today:hover {
            @apply bg-blue-400 text-white;
        }
    }
}

.flatpickr-time {
    border: none !important;

    input,
    .flatpickr-am-pm,
    .flatpickr-time {
        background: none !important;
    }
}

.flatpickr-time input.flatpickr-hour,
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    @apply font-semibold;

    &::selection {
        background: none;
    }
}

// -- Inistu Group

// Global

.g-no-underline {
    text-decoration: none;
}

.g-section-half {
    width: 53%;

    @include md {
        width: 100%;
    }
}

.g-section-prompt {
    display: block;
    position: relative;
    font-family: $heading-font;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    vertical-align: baseline;
    overflow: hidden;
    margin-bottom: 2em;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        top: 50%;
        margin-left: 10px;
        border-top: 1px solid $white;
        margin-left: 2em;
    }
}

.g-section-prompt-no-line {
    display: block;
    position: relative;
    font-family: $heading-font;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    vertical-align: baseline;
    overflow: hidden;
    margin-bottom: 1em;
}

.g-section-title {
    display: block;
    text-align: left;
    font-size: 2.25rem;
    letter-spacing: 0.8px;
    margin: 1em auto;

    @include xs {
        font-size: 2rem;
    }
}

.g-stat-4-col {
    display: grid;
    gap: 1.5em;
    grid-template-columns: repeat(4, 1fr);
    margin: 3em auto 5em;

    @include xs {
        margin: 2em auto;
        grid-template-columns: repeat(1, 1fr);
    }

    .col {
        width: 100%;
        margin: 0 auto;

        .stat-number {
            display: block;
            font-size: 2.85rem;
            font-family: $heading-font;
            font-weight: 600;
            text-align: center;

            @include xs {
                font-size: 2.25rem;
            }
        }

        .counter {
            animation-duration: 1s;
            animation-delay: 0s;
        }

        .stat-text {
            text-align: center;
            font-size: 0.75rem;
            margin-top: 0.5em;
            white-space: nowrap;
        }
    }
}

.g-stat-3-col {
    display: grid;
    gap: 1.5em;
    grid-template-columns: repeat(3, 1fr);
    margin: 3em auto;

    @include xs {
        margin: 2em auto;
        grid-template-columns: repeat(1, 1fr);
    }

    .col {
        width: 100%;
        margin: 0 auto;

        .stat-number {
            font-size: 2.85rem;
            font-family: $heading-font;
            font-weight: 600;

            @include xs {
                font-size: 2.25rem;
            }
        }

        .stat-text {
            text-align: center;
            font-size: 0.75rem;
            margin-top: 1em;
        }
    }
}

.g-three-col {
    display: grid;
    gap: 3em;
    grid-template-columns: repeat(3, 1fr);
    margin: 3em auto;

    @include lg {
        gap: 2em;
    }

    @include lg {
        margin: 2em auto;
        grid-template-columns: repeat(2, 1fr);
    }

    @include xs {
        grid-template-columns: repeat(1, 1fr);
    }

    .col {
        width: 100%;
        margin: 0 auto;
        list-style-type: none;
    }
}

.g-mobile-dropdown-menu {
    display: none;

    @include md {
        margin-bottom: 2em;
    }

    select {
        @include xs {
            font-size: 1rem;
        }
    }
}

// -- Header
header {
    position: relative;
    z-index: 2;

    .desktop-menu {
        width: 100%;

        .row {
            @extend .container;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1em 40px;

            @include md {
                flex-direction: row;
            }

            @include xs {
                padding: 1rem 20px;
            }
        }

        .column.left {
            display: flex;
            justify-content: flex-start;
        }

        .column.right {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            nav ul {
                li {
                    margin: 0 1em;

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        color: $white;
                        font-size: 1rem;
                        text-decoration: none;

                        &:hover {
                            color: $teal;
                        }
                    }
                }
            }
        }

        .logo {
            display: block;
            width: auto;
            max-width: 275px;

            @include md {
                height: 65px;
            }

            @include xs {
                height: 55px;
            }
        }
    }
}

// -- Mobile menu

.desktop-menu {
    display: block;

    @include lg {
        display: none;
    }
}

.mobile-menu {
    display: none;
    @extend .container;

    @include lg {
        position: relative;
        display: block;
        z-index: 101;
        padding: 1.5em 40px;
    }

    @include md {
        padding: 1.5em 20px;
    }

    .row {
        @include md {
            flex-direction: row;
        }
    }

    .logo-link {
        z-index: 1000;
    }

    .logo {
        width: 12em;

        @include xxs {
            width: 10em;
        }
    }

    .mobile-menu-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5em;

        .column.right {
            display: flex;
            flex-direction: row;
        }
    }
}

.burger-menu {
    display: none;

    @include lg {
        width: 30px;
        height: 30px;
        display: block;
        margin-left: 1.5em;
        padding: 0;
        border-bottom: 0 none;
        position: relative;
        right: 0;
        cursor: pointer;
        z-index: 101;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.7;
        }

        &.active {
            .top {
                background: $white;
                transform: translateY(11px) translateX(0) rotate(45deg);
            }

            .middle {
                opacity: 0;
            }

            .bottom {
                background: $white;
                transform: translateY(-9px) translateX(0) rotate(-45deg);
            }
        }

        span {
            background: $white;
            border: none;
            height: 2px;
            width: 100%;
            position: absolute;
            top: 4px;
            left: 0;
            transition: all 0.35s ease;
            cursor: pointer;
            z-index: 101;

            &:nth-of-type(2) {
                top: 14px;
            }

            &:nth-of-type(3) {
                top: 24px;
            }
        }
    }
}

.overlay {
    position: fixed;
    background: $black;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    // overflow: hidden;
    z-index: 100;

    &.open {
        opacity: 0.98;
        visibility: visible;
        height: 100%;

        li {
            animation: fadeInRight 0.5s ease forwards;
            animation-delay: 0.35s;

            &:nth-of-type(2) {
                animation-delay: 0.4s;
            }

            &:nth-of-type(3) {
                animation-delay: 0.45s;
            }

            &:nth-of-type(4) {
                animation-delay: 0.5s;
            }

            &:nth-of-type(5) {
                animation-delay: 0.55s;
            }

            &:nth-of-type(6) {
                animation-delay: 0.6s;
            }

            &:nth-of-type(7) {
                animation-delay: 0.65s;
            }

            &:nth-of-type(8) {
                animation-delay: 0.7s;
            }

            &:nth-of-type(9) {
                animation-delay: 0.75s;
            }

            &:nth-of-type(10) {
                animation-delay: 0.8s;
            }

            &:nth-of-type(11) {
                animation-delay: 0.85s;
            }

            &:nth-of-type(12) {
                animation-delay: 0.9s;
            }
        }
    }

    @include md {
        opacity: 0;
        visibility: hidden;
    }
}

.overlay-menu {
    display: block;

    nav {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;

        ul {
            display: flex;
            flex-direction: column;
            width: 100%;

            li {
                padding: 1em;

                a {
                    color: $white;
                    font-family: $font;
                    font-size: 1.125rem;
                    text-decoration: none;
                }
            }
        }
    }
}

// -- Footer

footer {
    .footer-top {
        align-items: center;
        border-top: 1px solid $white;
        padding: 1.5em 0;

        @include md {
            flex-direction: row;
            align-items: flex-end;
        }

        @include xs {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .column.left {
            display: flex;
            flex-direction: row;
            align-items: center;

            @include md {
                width: 100%;
                justify-content: space-between;
                flex-direction: column;
                align-items: flex-start;
            }

            .logo {
                height: 75px;

                @include xs {
                    max-height: 70px;
                }
            }

            .address-contact {
                padding-left: 2em;

                @include md {
                    padding: 1em 0 0 0;
                }
            }

            p {
                margin-bottom: 0.45em;
                text-transform: uppercase;
                font-size: 0.9rem;
            }
        }

        .column.right {
            .social-media {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 0.5em;

                @include xs {
                    justify-content: flex-start;
                }

                li {
                    list-style-type: none;
                    margin-left: 1em;
                    padding: 0.5em;
                    border: 1px solid $white;

                    &:hover {
                        border: 1px solid $teal;
                        background-color: $teal;
                    }

                    @include xs {
                        margin: 0 1em 0 0;
                    }
                }
            }

            small {
                display: block;
                text-align: right;
                margin: 0.75em 0;

                &:first-of-type {
                    margin-top: 1em;
                }

                @include xs {
                    text-align: left;
                    margin: 0.5em 0;
                }
            }

            @include xs {
                width: 100%;
                margin-top: 1em;
            }
        }
    }
}

// -- Main

main {
    position: relative;
    margin-top: -100px;
    z-index: 1;
}

// -- Homepage

.main-slider {
    display: block;
    height: 65vw;
    max-height: 1100px;
    margin-bottom: 0 !important;

    @include xs {
        height: 130vw;
    }

    .slick-slide {
        height: 65vw;
        max-height: 1100px;

        @include xs {
            height: 130vw;
        }

        .caption-container {
            position: relative;
            max-width: 1440px;
            height: 100%;
            margin: 0 auto;
            display: block;
        }

        .caption {
            top: 20rem !important;
            max-width: 45rem !important;
            background: transparent;
            padding: 0;
            text-align: left;

            @include lg {
                top: 15rem !important;
                max-width: 35rem !important;
            }

            @include md {
                top: 12rem !important;
                left: 1.5rem !important;
                max-width: 30rem !important;
            }

            @include xs {
                top: 10rem !important;
                max-width: 20rem !important;
            }

            h1 {
                margin: 0;
                font-size: 3rem;
                letter-spacing: 1.04px;
                text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
                    0px 8px 8px rgba(0, 0, 0, 0.1),
                    0px 8px 20px rgba(0, 0, 0, 0.1);

                @include lg {
                    font-size: 2.5rem;
                }

                @include md {
                    font-size: 2rem;
                }
            }
        }

        .slide-details {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: auto;
            transform: translate(-50%, 0);
            white-space: pre;

            p.slide-address {
                text-transform: uppercase;
                margin: 0 auto;
                padding-bottom: 0.5em;
                text-align: center;

                @include xs {
                    font-size: 0.75rem;
                }
            }
        }

        figure {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    .slick-dots {
        left: unset;
        right: unset;
        bottom: 2em;
        transform: none;

        li {
            width: 120px;
            height: 0;

            @include md {
                width: 80px;
            }

            @include xs {
                width: 60px;
            }

            &.slick-active {
                button:before {
                    content: "";
                    top: -1px;
                    border: 2px solid $white;
                    border-radius: 5px;
                }
            }

            button:before {
                content: "";
                width: 120px;
                height: 0;
                border: 1px solid $white;

                @include md {
                    width: 80px;
                }

                @include xs {
                    width: 60px;
                }
            }
        }
    }
}

#home-about {
    margin: 7em auto;

    @include md {
        margin: 4em auto;
    }

    .column {
        width: calc(50% - 2em);

        @include md {
            width: 100%;
        }

        &.text {
            .home-about-text {
                text-align: justify;
                line-height: 24px;
                letter-spacing: 0.9px;
            }
        }

        &.img {
            @include md {
                margin-top: 2em;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

#home-experience {
    margin: 7em auto;

    @include md {
        margin: 4em auto;
    }

    .home-experience-grid {
        display: flex;
        justify-content: space-between;
        margin-top: 4em;

        @include md {
            margin-top: 2em;
        }

        @include xs {
            flex-direction: column;
        }

        ul.side-tab-menu {
            width: 400px;

            @include xs {
                display: none;
            }

            li {
                position: relative;
                padding: 1.5em;
                border: 1px solid $white;
                list-style-type: none;

                button,
                a {
                    display: block;
                    text-align: left;
                    text-transform: uppercase;
                    text-decoration: none;
                    background: none;
                    border: none;
                    padding: 0;
                    margin: 0;

                    &.active {
                        &:after {
                            content: "";
                            background-image: url("/img/arrow-right.svg");
                            background-size: 16px 16px;
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            z-index: 2;
                            vertical-align: middle;
                            position: absolute;
                            right: 2em;
                        }
                    }

                    @include lg {
                        font-size: 85%;
                    }
                }
            }
        }

        .g-mobile-dropdown-menu {
            @include md {
                display: none;
            }

            @include xs {
                display: block;
                max-width: 250px;
            }
        }

        .home-experience-content {
            position: relative;
            width: 100%;
            text-align: left;
            margin-left: 4em;

            @include md {
                margin-left: 2em;
            }

            @include xs {
                margin-left: 0;
            }

            .home-exp-title {
                font-size: 1.25rem;
                font-weight: 600;
                font-family: $heading-font;
                letter-spacing: 1.2px;
                text-transform: uppercase;
            }

            .home-exp-text {

                &,
                &>p {
                    line-height: 28px;
                    margin: 1em 0;
                }
            }

            .home-exp-img {
                margin-top: 1em;
                width: 100%;
                height: auto;
                max-height: 380px;
                object-fit: initial;
                object-position: center;
            }
        }
    }
}

// -- CTA

#cta {
    .cta-bg {
        width: 100%;

        @include md {
            height: 150px;
            object-fit: cover;
            object-position: right;
        }
    }

    .row {
        margin: 2em auto;
        align-items: center;

        .column {
            &.left {
                width: calc(40% - 2em);
            }

            &.right {
                width: calc(60% - 2em);
            }

            @include md {
                width: 100% !important;
            }

            &.text {
                h3 {
                    text-align: left;
                    text-transform: uppercase;
                    font-size: 2.125rem;
                    margin-bottom: 0.5em;

                    @include xs {
                        font-size: 1.75rem;
                    }
                }

                p {
                    text-align: justify;
                    line-height: 24px;
                    letter-spacing: 0.9px;
                }
            }

            &.sliders {
                .slider-cta {
                    .slick-list {
                        width: 100%;
                    }

                    .slick-track {
                        display: flex;
                        margin: 0;
                        width: 100%;
                    }

                    .slick-slide {
                        width: 140px;
                        height: 140px;
                        margin: 0 1em;

                        @include xs {
                            width: 120px;
                            height: 120px;
                        }

                        &:before {
                            background-color: transparent !important;
                            opacity: 1 !important;
                        }

                        .slide-image {
                            opacity: 1;
                            height: 140px;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
        }
    }
}

// -- CMS Page

.cms-page {
    padding-bottom: 2em;

    p {
        line-height: 28px;
    }

    ul {
        padding-left: 1em;
        margin: 0.5em auto;

        li {
            line-height: 28px;
            letter-spacing: 0.8px;
        }
    }

    img {
        max-width: 100%;
    }
}

#page-header {
    position: relative;
    width: 100%;
    height: 112.5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include md {
        height: 100px;
        background-position: 75%, 50%;
    }

    @include xs {
        height: 100px;
        background-position: 75%, 50%;
    }

    &.experience-single-page {
        background-image: url("/img/default-page-header.png");
    }

    .layer {
        display: block;
        background-color: rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

#page-introduction {
    margin: 2em auto;
    text-align: left;

    h1 {
        margin: 0.5em 0;
        font-size: 2.25rem;

        @include xs {
            font-size: 2rem;
        }
    }
}

.content-block {
    margin: 3em auto;

    @include md {
        margin: 2em auto;
    }

    &.cols-2 {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        @include lg {
            padding: 0;
        }

        div {
            flex-basis: 50%;
            width: 100%;
            padding: 0 3em 0 0;

            &:last-child {
                padding: 0;
            }

            @include lg {
                flex-basis: 100%;
                width: 100%;
                padding: 0;
            }

            img {
                width: 100%;
                max-height: 400px;
                margin: 0;

                @include md {
                    margin-top: 1em;
                }
            }
        }
    }

    &.cols-3 {
        display: flex;
        flex-wrap: wrap;

        div {
            flex-basis: 33.3333333%;
            width: 100%;
            padding: 0 2em 0 0;

            img {
                max-height: 400px;
            }
        }
    }
}

.content-block {
    .text {
        text-align: left;

        h2 {
            line-height: 32px;
            margin: 1em 0;
        }

        img {
            height: 100%;
            object-fit: cover;
        }
    }
}

.accordion-title {
    color: $white;
    margin-bottom: 1em;
}

.accordion {
    .toggle .toggle-title .title-name {
        border-radius: 0;
    }

    .toggle:nth-child(even) .title-name {
        background: $teal;
        color: $white;
    }

    .toggle:nth-child(odd) .title-name {
        background-color: #ededf0;
        color: $teal;
    }

    .toggle .toggle-inner {
        background-color: #ece9f0;
        padding: 1em;
    }
}

.centered-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

// -- About page

#about-stat {
    .about-stat-text {
        width: 50%;
        padding-right: 3em;

        @include xs {
            width: 100%;
            padding-right: 0;
        }

        h3 {
            font-size: 2rem;
            margin-bottom: 1em;

            @include xs {
                font-size: 1.75rem;
            }
        }
    }
}

// -- Leadership page

#our-people {
    .g-three-col {
        padding-bottom: 2em;
        padding-left: 0;

        @include md {
            gap: 1em;
        }

        @include xs {
            padding-bottom: 1em;
        }

        .col {
            figure {
                position: relative;
                height: 100%;

                &:before {
                    content: "";
                    display: block;
                    background-color: rgba(0, 0, 0, 0.15);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    &:before {
                        background-color: rgba(46, 109, 110, 0.9);
                    }

                    figcaption {
                        position: absolute;
                        top: 0;

                        h4,
                        .gallery-asset-desc {
                            display: none;
                        }

                        .gallery-show-on-hover {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                }

                figcaption {
                    position: absolute;
                    top: calc(100% - 6em);
                    text-align: left;
                    padding: 1em;
                    height: 100%;
                    z-index: 2;

                    @include md {
                        padding: 1em 0.75em;
                    }

                    h4 {
                        font-size: 1.5rem;
                        margin-bottom: 0.25em;
                    }

                    .gallery-show-on-hover {
                        opacity: 0;
                        visibility: hidden;

                        .gallery-asset-long-desc {
                            cursor: default;

                            p {
                                font-size: 0.75rem;
                                line-height: normal;

                                @include md {
                                    font-size: 0.65rem;
                                }
                            }
                        }

                        ul.social-media {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            margin: 0.5em 0 0;

                            li {
                                list-style-type: none;
                                margin-right: 0.75em;
                                width: 27px;
                                height: 27px;
                                border: 1px solid $white;

                                img {
                                    display: block;
                                    margin: 0.35em auto;
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// -- Experience Page

#experience {
    #page-introduction {
        text-align: center;
    }
}

.exp-tab-selectors {
    margin: 4em auto 3em;

    @include xs {
        margin: 2em auto;
    }

    .tab-selectors {
        display: flex;
        justify-content: space-between;

        @include md {
            display: none;
        }

        li {
            list-style-type: none;
            font-size: 1.125rem;
            line-height: 28px;
            letter-spacing: 0.9px;
            text-transform: uppercase;
            padding-bottom: 0.25rem;
            border-bottom: 1px solid $white;

            @include lg {
                font-size: 1rem;
            }

            a {
                text-decoration: none;
            }
        }
    }

    .g-mobile-dropdown-menu {
        @include md {
            display: block;
            max-width: 100%;
        }
    }
}

.tab-selected {
    display: flex;
    align-items: baseline;
    margin-bottom: 3em;

    @include xs {
        margin-bottom: 2em;
    }

    span {
        padding-left: 0.5em;
        font-weight: 700;
        font-size: 1.5rem;

        @include xs {
            font-size: 1.25rem;
        }
    }
}

.experience-tiles-grid {
    @include tile-grid;

    .experience-tile-item {
        @include tile;
        position: relative;
        margin-bottom: 2em;
        overflow: hidden;
        height: 250px;

        @screen md {
            height: 300px;
        }

        @screen lg {
            height: 300px;
        }

        &:hover {
            figure img {
                -webkit-transform: scale(1.08);
                transform: scale(1.08);
            }
        }

        figure {
            position: relative;
            height: 100%;

            &:hover:before {
                content: "";
                display: block;
                background-color: rgba(0, 0, 0, 0.3);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                transition: all 0.3s ease-in-out;
            }

            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
                object-position: bottom;
                transition: 0.4s ease;

                @include xs {
                    height: 250px;
                }
            }
        }

        figcaption {
            position: absolute;
            bottom: 2em;
            left: 2em;
            width: 90%;
            z-index: 2;

            @include xs {
                left: 1em;
            }

            p {
                font-size: 1.25rem;
                font-weight: 600;
                font-family: $heading-font;
                line-height: normal;
                text-align: left;
                //letter-spacing: 1.6px;
                //text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.25), 1px -1px 0 rgba(255, 255, 255, 0.25), -1px 1px 0 rgba(255, 255, 255, 0.25), 1px 1px 0 rgba(255, 255, 255, 0.25);

                @include xs {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

// -- Experience Single page

.exp-details {
    margin: 2em 0 1em;

    .exp-details-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.25em;
    }
}

#page-introduction.experience-single-page {
    .row {
        .column {
            width: calc(50% - 2em);

            @include md {
                width: 100%;
            }

            &.img {
                @include md {
                    margin-top: 1em;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.experience-tiles-grid {
    &.experience-single-page {
        .experience-tile-item {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}

// -- Contact page

#enquiries {
    margin: 2em auto;

    @include lg {
        margin: 1em auto;
    }

    .text {
        font-size: 1.125rem;

        @include xs {
            font-size: 1rem;
        }
    }

    .email-phone {
        justify-content: flex-start;

        @include md {
            margin: 0;
            align-items: flex-start;
        }

        p {
            &:last-child {
                margin-left: 2em;

                @include md {
                    margin-left: 0;
                }
            }
        }
    }

    .social-media {
        display: flex;
        margin-top: 2em;
        padding-left: 0;

        li {
            list-style-type: none;
            margin-right: 1em;
            padding: 0.5em;
            border: 1px solid $white;

            &:hover {
                border: 1px solid $teal;
                background-color: $teal;
            }

            .socmed-icon {
                img {
                    display: block;
                    width: 24px;
                    height: 24px;
                    max-height: 100%;
                    margin-top: 0;
                }
            }
        }
    }

    .contact-form {
        @include lg {
            margin-top: 2em;
        }
    }
}

#careers {
    margin: 2em auto;

    @include lg {
        margin: 1em auto 0;
    }

    .careers-form {
        @include lg {
            margin-top: 2em;
        }
    }
}


// Video modal

.video-modal {
    display: flex;
    position: fixed;
    top: 50vh;
    left: 50vw;
    background: $white;
    // max-width: 60rem;
    // max-height: calc(100% - 2.5rem);
    border: 2px solid $white;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba($black, 0.15);
    transition: all 0.25s ease;
    flex-direction: column;
    translate: -50% -50%;
    visibility: hidden;
    opacity: 0;
    z-index: 4;

    &.active {
        visibility: visible;
        opacity: 1;
    }

    .close {
        position: absolute;
        top: -0.75rem;
        right: -0.75rem;
        background: $white;
        width: 1.5rem;
        height: 1.5rem;
        padding: 0.125rem;
        transition: all 0.25s ease;
        border-radius: 10rem;
        border: none;
        color: $black;

        &::before {
            content: none;
        }

        &:hover {
            background: $negative;

            i {
                background: $white;
            }
        }

        i {
            display: block;
            background: $black;
            mask: url('/img/icon-close.svg') 50% 50% / 0.75rem no-repeat;
            width: 1.25rem;
            height: 1.25rem;
            transition: all 0.25s ease;
        }
    }

    video {
        width: calc(100vw - 2rem);
        max-width: 60rem;
        border-radius: 0.5rem;
    }
}

.video-overlay {
    position: fixed;
    background: rgba($black, 0.5);
    visibility: hidden;
    opacity: 0;
    inset: 0;

    &.active {
        visibility: visible;
        opacity: 1;
    }
}
