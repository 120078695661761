@mixin xxs {
    @media (max-width: 350px) {
        @content;
    }
}

@mixin xs {
    @media (max-width: 699px) {
        @content;
    }
}

@mixin xs2 {
    @media (max-width: 800px) {
        @content;
    }
}

@mixin md {
    @media (max-width: 900px) {
        @content;
    }
}

@mixin lg2 {
    @media (max-width: 1100px) {
        @content;
    }
}

@mixin lg {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin xl {
    @media (min-width: 1800px) {
        @content;
    }
}
