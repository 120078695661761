.message {
    padding: 0.75em 0;
    text-align: center;
    clear: both;

    p {
        margin: 0 auto;
    }

    &.negative {
        background: $negative;
        color: $white;
        text-align: center;
        margin-bottom: 10px;
        p {
            margin: 0;
        }
    }

    &.positive, &.success {
        background: $positive;
        color: $white;
    }
}

span.error {
    float: left;
    display: block;
    width: 100%;
    margin: 0.5em 0 1em 0;
    font-size: 80%;
    text-align: left;
    color: $negative;

    &:empty {
        display: none;
    }
}

p.error {
    text-align: center;
    color: $negative;
}
