@tailwind utilities;

@mixin tile-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1em 2em;
}

@mixin tile {
  width: 100%;

  @screen md {
    width: calc(50% - 1em);
  }

  @screen lg {
    width: calc(100% / 3 - 1.35em);

    &:nth-child(10n-9),
    &:nth-child(10n-3) {
      width: calc(60% - 1em);
    }

    &:nth-child(10n-8),
    &:nth-child(10n-4) {
      width: calc(40% - 1em);
    }
  }
}