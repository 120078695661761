button,
.button {
    display: table;
    padding: $btn-padding;
    transition: 0.3s ease-in-out;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    letter-spacing: 1.2px;
    line-height: inherit;
    text-decoration: none;
    border: 1px solid $white;
    color: inherit;
    background: transparent;
    text-transform: uppercase;
    margin: 1em 0;

    &:hover {
        color: $white;
        background-color: $teal;
        border: 1px solid $teal;
        transition: 0.3s ease-in-out;
    }

    &:focus {
        outline: 0;
    }

    &.primary {
        background: $primary;
        color: $white;

        &:hover {
        }
    }

    &.border {
        background: transparent;
        box-shadow: inset 0 0 0 1px $primary;
        border: none;
        color: $primary;

        &:hover {
            background: $primary;
            color: $white;
        }
    }

    &.arrow {
        padding: 0.625rem 0.75rem;
        margin-left: 0.75em;
        background: transparent;

        &:hover {
            border: 1px solid $teal;
            background-color: $teal;
            transition: 0.3s ease-in-out;
        }

        &:before {
            content: "";
            background-image: url("/img/arrow-right.svg");
            background-size: 16px 16px;
            display: inline-block;
            width: 16px;
            height: 16px;
            z-index: 2;
        }
    }

    &.arrow-text {
        border: none;
        padding: 0;
        margin-top: 1.5em;
        text-transform: uppercase;
        background: transparent;

        &:before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            z-index: 2;
            padding: 1rem 1rem;
            margin-right: 1em;
            vertical-align: middle;
            border: 1px solid $white;
            background-image: url("/img/arrow-right.svg");
            background-size: 16px 16px;
            background-repeat: no-repeat;
            background-position: center;
        }

        &:hover {
            transition: 0.3s ease-in-out;

            &:before {
                border: 1px solid $teal;
                background-color: $teal;
            }
        }
    }

    &.load-more {
        margin: 2em auto 4em;
    }
}
